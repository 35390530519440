# this is a simple auth service that checks for the gon object and if it doesn't have a session key in it we are returning
# that the session is not valid. it's not meant to be for rock solid auth. it's mostly so we can redirect the user to
# an error page when they don't have a valid session. the 'real' auth logic is happening on each endpoint. without a valid
# token API's won't return data anyways. rather than just show the app in a broken state we'll perform redirects here
angular.module('globalHelpersModule').factory 'AuthService', ($log, $http, $state, $location, jwtHelper, UtilityService) ->
  session = Object.assign({}, gon)

  deleteAuthHeader: ->
    delete $http.defaults.headers.common['Authorization']

  expireAuthToken: ->
    date = new Date('01/01/2000')
    document.cookie = "#{ window.gon.config.PHR_AUTH_TOKEN_KEY }=;expires=#{ date.toUTCString() };path=/;"

  getAuthTokenFromCookie: ->
    authTokenPart = document.cookie.split(';').find((segment) ->
      segment.indexOf(window.gon.config.PHR_AUTH_TOKEN_KEY) > -1
    )

    [key, authToken] = (authTokenPart || '').split('=');

    return authToken;

  getAuthToken: ->
    session?.authToken

  # public facing doctor profile photo paths
  getBuiltProfilePhotoUrl: (providerProfileGuid) ->
    return 'http://s3.amazonaws.com/pf_aws_int_profiles/files/' + providerProfileGuid + '/profilephoto.jpg' if @getEnvironment() == 'test'
    return 'http://s3.amazonaws.com/pf_aws_int_profiles/files/' + providerProfileGuid + '/profilephoto.jpg' if @getEnvironment() == 'development'
    return 'http://s3.amazonaws.com/pf_aws_int_profiles/files/' + providerProfileGuid + '/profilephoto.jpg' if @getEnvironment() == 'int'
    return 'http://s3.amazonaws.com/pf_aws_qa_profiles/files/' + providerProfileGuid + '/profilephoto.jpg' if @getEnvironment() == 'qa'
    return 'http://s3.amazonaws.com/foobar_profiles/files/' + providerProfileGuid + '/profilephoto.jpg' if @getEnvironment() == 'rc'
    return 'http://pf-stg-pubfiles.s3.amazonaws.com/files/' + providerProfileGuid + '/profilephoto.jpg' if @getEnvironment() == 'staging'
    return 'https://pfpubfiles.s3.amazonaws.com/files/' + providerProfileGuid + '/profilephoto.png' if @getEnvironment() == 'production'

  getConfigData: ->
    session.config

  getEnvironment: ->
    session?.env

  getError: ->
    session?.error

  getIsAgreementAccepted: ->
    session?.isAgreementAccepted

  getIsAgreementRequired: ->
    session?.isAgreementRequired

  getPatientPracticeGuid: ->
    session?.patientPracticeGuid

  getPhrUserGuid: ->
    session?.phrUserGuid

  getPracticeGuid: ->
    session?.practiceGuid || ''

  getProviderGuid: ->
    session?.providerGuid

  getProviderPhotoUrl: ->
    session?.providerPhotoUrl

  # retrieve a value for a query parameter
  # http://stackoverflow.com/questions/2090551/parse-query-string-in-javascript
  getQueryVariable: (variable) ->
    query = window.location.search.substring(1)
    vars = query.split("&")
    i = 0

    while i < vars.length
      pair = vars[i].split("=")
      return decodeURIComponent(pair[1])  if decodeURIComponent(pair[0]) is variable
      i++
    # console.log "Query variable %s not found", variable

  getRoute: ->
    session?.route

  getSessionData: ->
    session

  getSessionKey: ->
    session?.sessionKey

  getSignoutUrl: ->
    this.getConfigData().INTAKE_LOGIN_URL + '?success=UserLoggedOut'

  getSignoutUrlSessionExpired: ->
    redirect = '&redirect=' + encodeURIComponent(window.location.href)
    this.getConfigData().PF_LOGIN_URL + '?success=SessionExpired' + redirect

  getSubjectGuid: ->
    session?.subjectGuid

  getSurveyParams: ->
    if session?.surveyParams
      # restangular doesn't deal nicely with nested objects as query parameters
      # so we're going to flatten the options hash out for it
      options = session.surveyParams.options
      delete session.surveyParams.options

      for opt of options
        session.surveyParams['options[' + opt + ']'] = options[opt]

      return session.surveyParams
    else
      return session?.surveyParams

  getSurveyType: ->
    if session?.route is 'patient/surveys'
      return 'survey_intakes'
    else if session?.route is 'patient/appointments'
      return 'appointment_intakes'

  # survey type ('AppointmentIntake', etc)
  hasFormType: ->
    session?.formType? and session?.formType != ''

  hasNonEmptyAuthToken: ->
    session?.authToken? and session?.authToken != ''

  hasNonEmptySessionKey: ->
    session?.sessionKey? and session?.sessionKey != ''

  hasPatientPracticeGuid: ->
    session?.patientPracticeGuid? and session?.patientPracticeGuid != ''

  hasPracticeGuid: ->
    session?.practiceGuid? and session?.practiceGuid != ''

  hasProviderPhotoUrl: ->
    session?.providerPhotoUrl? and session?.providerPhotoUrl != ''

  # survey guid
  hasSubjectGuid: ->
    session?.subjectGuid? and session?.subjectGuid != ''

  hasValidAuthToken: ->
    if this.hasNonEmptyAuthToken()
      if this.getEnvironment() is 'test'
        return true
      isValid = false
      try
        isValid = !jwtHelper.isTokenExpired(session?.authToken)
      catch e
        this.expireAuthToken()
      finally
        return isValid
    return false

  initializeAuthenticatedSession: ->
    if this.isPhrUser() and this.getEnvironment() isnt 'test'
      session?.authToken = this.getAuthTokenFromCookie()

    if this.hasValidAuthToken()
      token = 'Bearer ' + this.getAuthToken()
      this.setAuthHeader(token)
      return true
    else
      this.deleteAuthHeader()
      if this.getEnvironment() is 'development'
        if $location.path() != '/dev_landing_page/'
          appointmentGuid = $location.search().appointment_guid
          qs = ''
          if UtilityService.isValidGuid(appointmentGuid)
            qs = '?appointmentGuid=' + appointmentGuid
          $location.url('/dev_landing_page/' + qs)
      else
        window.location.href = this.getSignoutUrlSessionExpired()

      return false

  isAdministrator: ->
    session?.isAdministrator || false

  isAppointment: ->
    true

  isPhrUser: ->
    session?.isPhrUser || false

  isProvider: ->
    session?.isProvider || false

  isSurvey: ->
    false

  setAuthCookie: (token) ->
    document.cookie = "phr_auth_token=#{ token }"

  setAuthToken: (authToken) ->
    session?.authToken = authToken

  setAuthHeader: (token) ->
    $http.defaults.headers.common['Authorization'] = token

  setError: (error) ->
    session?.error = error

  # update the attribute in memory when an agreement accepted
  setIsAgreementRequired: (bool) ->
    session?.isAgreementRequired = bool

  setIsPhrUser: (isPhrUser) ->
    session?.isPhrUser = isPhrUser

  setPatientPracticeGuid: (patientPracticeGuid) ->
    session?.patientPracticeGuid = patientPracticeGuid

  setProvider: (provider) ->
    session?.ehrUserGuid = provider.ehr_user_guid
    session?.isAdministrator = provider.is_administrator
    session?.isProvider = true
    session?.practiceGuid = provider.practice_guid
    session?.providerGuid = provider.provider_guid
    session?.providerPhotoUrl = provider.provider_profile_photo_url

  setRoute: (route) ->
    session?.route = route

  setSessionKey: (sessionKey) ->
    session?.sessionKey = sessionKey

  setSubjectGuid: (subjectGuid) ->
    session?.subjectGuid = subjectGuid

  signoutUserFromAuth0: ->
    this.expireAuthToken()

    config = this.getConfigData()
    auth0SignoutUrl = config.AUTH0_DOMAIN_URL +
      '/v2/logout?client_id=' +
      config.AUTH0_API_CLIENT_ID +
      '&returnTo=' +
      encodeURIComponent(this.getSignoutUrl())

    window.location = auth0SignoutUrl